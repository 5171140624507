@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  /* font-size: 62.5%; */
  font-size: 100%;
}
body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(240, 242, 245);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  /* -webkit-box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12); */
}
.ag-theme-alpine .ag-header-cell-label {
  font-style: italic;
}

.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
  --ag-background-color: #fff;
}
.ag-theme-alpine {
  --ag-foreground-color: #fff;
  --ag-background-color: #fff;
  --ag-header-foreground-color: #fff;
  --ag-header-background-color: #fff;
  --ag-odd-row-background-color: #fff;

  --ag-borders: none !important;
  --ag-row-border-style: none;
  --ag-row-border-color: transparent;
  --ag-row-border-width: 0;
}
.ag-paging-panel {
  border: none !important;
}

/* scroll bar css */

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d1d1;
  border-radius: 99999px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3e3d3e;
  border-radius: 99999px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* Handle on hover */
  background: #3e3d3e;
  transition: linear 300ms;
}

@media (max-width: 1600px) {
  html {
    font-size: 95%;
  }
}
@media (max-width: 1400px) {
  html {
    font-size: 90%;
  }
}
